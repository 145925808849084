import { createApp } from 'vue'
import ImageViewer from './ImageViewer.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import App from './App.vue'
import Home from './Home.vue'
import CrowdViewer from './crowd/CrowdViewer.vue'
import 'bootstrap';
import $ from 'jquery'
import { Buffer } from 'buffer';
import { createWebHistory, createRouter } from "vue-router";
// import Home from './Home.vue'
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
window.$ = require('jquery');
window.Buffer = Buffer;

// 1. Define route components.
// These can be imported from other files


// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    { name: "Home", path: '/', component: Home },
    { name: "ImageViewer", path: '/ocr/:id', component: ImageViewer },
    { name: "CrowdViewer", path: '/countwithus', component: CrowdViewer },
    { path: "/:catchAll(.*)", redirect: "/" },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)

app.mount('#app')

// createApp(App).mount('#app')
