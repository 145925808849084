// let host = 'http://localhost'
// let host = 'http://192.168.100.11'
let host = 'https://api.kenyaelection.live'
let config = {

    api: {
        "nextImages": host + "/next",
        "submission": host + "/submission",
        "dashboard": host + "/dashboard",
        "dashboard5": host + "/dashboard5",
    }
}

export default config;