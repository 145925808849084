let state = {
    stations: [],
    ImageViewer: {
        station: null
    },
    thanksMessages: [
        "Thank you for your submission",
        "Thank you so much",
        "Asanti kwa maoni yako",
        "This must be Chebu, thanks :)",
        "Nice work, thanks :)",
        "Wewe ni ule msee",
        "Nice one, thanks",
        "Mr./Mrs presiding ofisa, asanti",
        "Asanti kwa maoni yako",
        "Keep going, thanks!",
        "Break the high score, for submission",
        "We are proud of you",
        "Wewe ni mtu wa madhe matick😂",
        "Si unajua maths, ulisomea wapi? 😂",
        "You are a lifesaver",
        "Thanks a ton",
        "Mkenya halisi",
        "Thanks a lot",
        "Asanti sana",
        "IEBC should hire you",
        "Consider yourself heartily thanked",
        "You made my day",
        "We can’t thank you enough",
        "It was so awesome of you",
        "Keep going, dont stop",
        "Keep going, no one can stop you",
        "If you ever need anything…",
        "Thank you for never letting us down",
        "We don’t know what we would do without you"

    ]

}
export default state