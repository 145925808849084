<template>
  <div>

    
    <!-- welcome -->
    <div class="heading m-5 mb-2">
      <a href="/#" class="btn btn-dark mb-2">&lt; Home</a>
      <h1>Crowd Source Tallying</h1>
      
      <p>
        Hi, please help us review the results below. 
        <ul>
          <li>Read the value in the image</li>
          <li>Enter the values in the form below</li>
          <li>You dont have to put leading zeros: <i> Example for "002" put 2</i></li>
          <li>If the numbers presented are different from what is on the form. Please edit them and click [SAVE]</li>
          <li>If the form is upside down/side ways click [ROTATE]</li>
          <li>Click [SAVE] after keying the data, and wait for the next image to load</li>
          <li>The tally below will only update after mulitple people have reviewed the same form.</li>
          <li>Thank you.</li>
        </ul>
        Some values are already filled. <b>Please confirm if  the values are correct and change if its not correct</b> 
      </p>
      <p>
        Click the whatsapp/twitter share button below to get more people verifying the data.
      </p>
      <p>
        <b>Tips: Scroll to the bottom if you are on mobile. If possible use a computer for better viewing. Use Tab Key on your computer keyboard for easier data entry.</b>
      </p>
    </div>
    <div class="d-flex justify-content-center mb-4">
      <button @click="showResults=!showResults" class="btn btn-primary btn-lg m-2">{{!showResults? 'Click to See Results' : 'Click to Hide Results'}}</button>    
    </div>

    <!-- results section -->
      <div v-if="showResults" class="container d-flex align-items-center flex-column mb-4">
        <!-- Masthead Heading-->   
        <table width="100%" class="ml-5">
          <tr width="100%">
            <td style="text-align: initial">
              <img class="candidate-image" src="/assets/odinga.jpg" alt="" />
              <h4 class="f-20 m-2">
                Raila
                <span class="count">{{
                  datasets[datakey].Raila.toLocaleString()
                }}</span>
              </h4>
            </td>
            <td style="text-align: right">
              <img class="candidate-image" src="/assets/ruto.jpg" alt="" />
              <h4 class="f-20 m-2">
                Ruto
                <span class="count">{{
                  datasets[datakey].Ruto.toLocaleString()
                }}</span>
              </h4>
              <h3 class="text-danger"> &#10003; President Elect</h3>
              <h6><a href="https://www.facebook.com/IEBCKenya/videos/2938041656495188/" target="_blank">IEBC Announcement Video</a></h6>
            </td>
          </tr>
        </table>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light"></div>

        <div class="d-flex flex-column mb-2">
          <h5 class="font-Inter font-semibold text-slate-500">
            50% + 1 to Win
          </h5>
          <i class="fas fa-arrow-down"></i>
        </div>

        <div class="bar w-100">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{
                width: percents.railaPercents + '%',
                backgroundColor: 'blue',
              }"
              sty
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            <div
              class="progress-bar bg-warning"
              role="progressbar"
              :style="{ width: percents.rutoPercents + '%' }"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <!-- Masthead Subheading-->
        <p class="font-weight-light m-2 f-09">
          <small class=""
            >These results depend on your accuracy please take your time. Results will be updated every 5mins</small
          >
        </p>


        <div class="mb-3 row">
          <div class="col-auto">
            <select class="form-select" aria-label="Default select example" @change="onSelect($event)">         
              <option selected value="dashboard">Crowd Verified Results</option>
              <option  value="official">Official IEBC Results</option>
              <!-- <option value="dashboard5">Five or more verifications</option>   -->
            </select>
          </div>
        </div>
        

        

        <h3>Verified So Far  {{datasets[datakey].filesWithSubmissions.toLocaleString()}}/46,229</h3>
        <div class="progress v-bar">          
          <div class="progress-bar bg-danger" role="progressbar" :style="{width: vPercents+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{vPercents}}%</div>
        </div>
        
      </div>
    <!-- end -->


    <div class="container">
      <div class="row">
        <!-- image card -->
        <div class="card col-lg-6 col-sm-12 p-2 m-1" >
          <div class="card-body" v-if="isMobile()">
            <h6 class="card-title">Form 34A: {{station.fileName}}</h6>
            <p class="card-text">
              Use this image to check on the data. <small class="text-bg-secondary">click and hold image to move around</small>
            </p>            
          </div>
          
          <!-- scroll window -->
          <div v-if="currentImage.image!=null" ref="window" class="dragscroll drag-box" :class="{'drag-mobile':isMobile()}">
              <img  ref="image" class="card-img-top" @load="updateDrag" :class="{'raw-image':true}" :src="currentImage.image" alt="Card image cap" />
          </div>
          
          <!-- loading -->
          <div v-if="currentImage.image==null" class="d-flex justify-content-center align-items-center" style="height:50vh"  >
            <div>
              <!-- <p class="p-5" style="font-size:4rem;">Loading... </p> -->
                <div class="spinner-border align-self-center"  role="status" style="width: 5rem; height: 5rem;">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
            
          </div>
          <!-- end loading -->
          <div class="card-body" v-if="!isMobile()">
            <h6 class="card-title">Form 34A: {{station.fileName}}</h6>
            <p class="card-text">
              Use this to check on the data. <small class="text-bg-secondary">click and hold image to move around</small>
            </p>            
          </div>
          
        </div>

        <!-- table form card -->
        <div class="card col-lg-4 col-sm-12 p-2 m-1" >
          <div class="card-body">
            <h5 class="card-title">Verify Info </h5>
            <p class="card-text">
              <form>
                <div class="form-group row">
                  <label for="staticEmail" class="col-6 col-form-label">Raila Odinga</label>
                  <div class="col-6">
                     <input type="number" ref="railaInput" class="form-control" v-model.number="station.Raila" placeholder="Raila Votes">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="staticEmail" class="col-6 col-form-label">William Ruto</label>
                  <div class="col-6">
                     <input type="number" class="form-control" v-model.number="station.Ruto" placeholder="Ruto Votes">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="staticEmail" class="col-6 col-form-label">David Waihiga</label>
                  <div class="col-6">
                     <input type="number" class="form-control" v-model.number="station.David" placeholder="David Votes">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="staticEmail" class="col-6 col-form-label">George Wajackoyah</label>
                  <div class="col-6">
                     <input type="number" class="form-control" v-on:keyup.enter="save" v-model.number="station.WJ" placeholder="Wajackoyah Votes">
                  </div>
                </div>
              </form>
              <div class="d-flex justify-content-end">
              <p class="float-end fw-bold mt-2">Total:  {{total}}</p>
              </div>
            <button @click="rotate" class="btn btn-dark float">Rotate</button>
            <button @click="save" class="btn btn-primary float-end">Save</button>
            <div class="fw-light Blink text-center m-3 text-success" v-if="saves%5!=0">{{thanksMessage}}</div>
            </p>

            <div class="gloat" @click="shareOnTwitter" v-if="saves>0 && saves%5==0">
            <em>Thank you.</em><br>
            You have verified <b>{{saves}}</b> Form 34A's.  <em class=text-primary>Click to share on Twitter</em>
          </div>
          </div>

          

          <button class="btn btn-primary" @click="shareOnTwitter" target="_blank">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
              </svg>
                Share on Twitter
              </button>
          
        </div>
      </div>
    </div>

    <div class="spacer-button">
      
    </div>
    <!-- share widget -->
    <div class="share-widget m-4">
      <div target="_blank" tooltip="Share on WhatsApp" @click="shareWhatsapp">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100px" height="100px" fill-rule="evenodd"
          clip-rule="evenodd">
          <path fill="#fff"
            d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z" />
          <path fill="#fff"
            d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z" />
          <path fill="#cfd8dc"
            d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z" />
          <path fill="#40c351"
            d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z" />
          <path fill="#fff" fill-rule="evenodd"
            d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
            clip-rule="evenodd" />
        </svg>
       
      </div>
    </div>
    <!-- end share -->
  </div>
</template>

<script>
import config from "../config.js";
import axios from "axios";
import state from "../state.js";

export default {
  data: () => {
    return {
      images: [],
      currentImage: { iamge: null },
      nextImagePromise: null,
      station: {},
      angle: 90,
      datakey: "dashboard",
      thanksMessage: "",
      showResults: false,
      thanksTimeout: null,
      saves: 0,
      datasets: {
        dashboard: {
          Raila: 0,
          Ruto: 0,
          WJ: 0,
          David: 0,
          filesWithSubmissions: 0,
        },
        dashboard5: {
          Raila: 0,
          Ruto: 0,
          WJ: 0,
          David: 0,
          filesWithSubmissions: 0,
        },
        official: {
          Raila: 6942930,
          Ruto: 7176141,
          WJ: 61989,
          David: 31987,
          filesWithSubmissions: 46201,
        },
        ai: {
          Raila: 6683469,
          Ruto: 6691224,
          WJ: 59206,
          David: 31171,
        },
      },
      percents: {
        railaPercents: 0,
        rutoPercents: 0,
      },
    };
  },
  computed: {
    total() {
      let num =
        this.station.Raila +
        this.station.Ruto +
        this.station.David +
        this.station.WJ;
      if (isNaN(num)) return 0;
      return num;
    },
    vPercents() {
      return Math.floor(
        (this.datasets[this.datakey].filesWithSubmissions / 46229) * 100
      );
    },
  },
  methods: {
    onSelect(event) {
      this.datakey = event.target.value;
      this.setPercents();
    },
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    setThanks() {
      this.thanksMessage = "";
      //get random array
      let random = Math.floor(Math.random() * state.thanksMessages.length);
      this.thanksMessage = state.thanksMessages[random];
      clearTimeout(this.thanksTimeout);
      this.thanksTimeout = setTimeout(() => {
        this.thanksMessage = "";
      }, 4500);
    },
    rotate() {
      this.angle += 90;
      this.$refs.image.style.transform = `rotate(${this.angle}deg)`;
    },
    setPercents() {
      let Raila = this.datasets[this.datakey].Raila;
      let Ruto = this.datasets[this.datakey].Ruto;
      this.percents.railaPercents = (Raila / (Ruto + Raila)) * 100;
      this.percents.rutoPercents = (Ruto / (Ruto + Raila)) * 100;
    },
    async getDashboard() {
      let res = await axios.get(config.api.dashboard);
      this.datasets.dashboard = res.data;
      await this.getDashboard5();
      this.setPercents();
    },
    async getDashboard5() {
      let res = await axios.get(config.api.dashboard5);
      this.datasets.dashboard5 = res.data;
    },
    updateDrag() {
      window.dragscroll.reset();
      this.$refs.window.scrollLeft = this.$refs.window.scrollWidth;
      this.$refs.window.scrollTop = (this.$refs.window.scrollHeight * 1.8) / 10;
    },
    async setImage() {
      this.currentImage = { iamge: null };
      let unprocessedImages = this.images.filter((image) => !image.saved);

      //wait for atleast one promise to be resolved
      let promises = unprocessedImages.map((image) => image.imagePromise);
      //wait of any promises to be resolved

      let image1 = await Promise.any(promises);

      let data = unprocessedImages.find((image) => image.image != null);

      this.currentImage = data;
      this.angle = 0;
      if (!this.isMobile()) {
        setTimeout(() => {
          // this.updateDrag();
        }, 2);
      }
      this.station = {
        Raila: data.Raila,
        Ruto: data.Ruto,
        David: data.David,
        WJ: data.WJ,
        fileName: data.fileName,
      };
    },
    async getNextImages() {
      let res = await axios.get(config.api.nextImages);

      let newImages = JSON.parse(res.data.next);

      newImages.forEach((data) => {
        data.image = null;
        data.saved = false;
      });
      let processedFileNames = this.images
        .filter((data) => data.saved)
        .map((data) => data.fileName);
      // console.log("processedFileNames :>> ", processedFileNames);
      newImages = newImages.filter(
        (data) => !processedFileNames.includes(data.fileName)
      );
      if (newImages.length == 0) {
        // return await this.getNextImages();
      }
      this.images.push(...newImages);
      this.nextImagePromise = null;
      //create promises
      newImages.forEach(async (imageInfo, index) => {
        let imageUrl = `https://form34a.s3.amazonaws.com/${imageInfo.folder}/${imageInfo.fileName}.jpg`;
        // console.log(`getting image ${imageUrl}`, index);
        imageInfo.imagePromise = this.getBase64(imageUrl, imageInfo);
      });
      this.setImage();
    },
    async getBase64(url, imageInfo) {
      let res = await axios.get(url, {
        responseType: "arraybuffer",
      });
      // console.log("array", res.data);
      imageInfo.image =
        "data:image/png;base64," +
        Buffer.from(res.data, "binary").toString("base64");
    },
    getshareText() {
      let text = encodeURIComponent(
        `Help verify Form 34A's on https://kenyaelection.live/countwithus .\nI have verified ${this.saves} so far.`
      );
      if (this.saves == 0) {
        text = encodeURIComponent(
          `Help verify Form 34A's on https://kenyaelection.live/countwithus .\n`
        );
      }
      return text;
    },
    async shareOnTwitter() {
      let text = this.getshareText();
      let link = `https://twitter.com/intent/tweet?text=${text}`;
      window.gtag("event", "twittershare");
      window.open(link, "_blank");
    },
    shareWhatsapp() {
      let text = this.getshareText();
      let link = `https://api.whatsapp.com/send?text=${text}`;
      window.gtag("event", "sharewhatsapp");
      window.open(link, "_blank");
    },

    async save() {
      // console.log("saving", this.currentIndex);
      //skip saving if image is not loadedImages
      if (this.currentImage == null) {
        return;
      }
      window.gtag("event", "save");
      //send data to server
      axios.post(config.api.submission, {
        station: this.station,
      });
      this.currentImage.saved = true;
      // this.datasets.dashboard.filesWithSubmissions++;
      this.saves++;
      localStorage.setItem("saves", this.saves);
      this.getDashboard();

      let unsaved = this.images.filter((image) => !image.saved);
      this.setImage();
      if (Math.random() < 0.1) this.setThanks();
      this.$refs.railaInput.focus();
      this.$refs.railaInput.select();

      if (unsaved.length < 5) {
        if (this.nextImagePromise) {
          await this.nextImagePromise;
        } else {
          this.nextImagePromise = this.getNextImages();
        }
      }

      //update the data with averages
      //load next image
    },
  },
  mounted() {
    // this.$refs.image.onload = () => console.log("image loadded");
    let saves = localStorage.getItem("saves");
    if (saves !== null) this.saves = saves;
    this.setPercents();
    this.getDashboard();
    this.getNextImages();
  },
};
</script>

<style>
.drag-box {
  width: 100%;
  height: 60vh;
  overflow: auto;
}
.drag-mobile {
  height: 40vh;
}
.raw-image {
  width: 200%;
}
.spacer-button {
  height: 120px;
}
.share-widget {
  position: fixed;
  left: 0;
  bottom: 0;
  right: unset;
}
</style>
<style scoped>
td {
  vertical-align: top;
}
.count {
  text-shadow: none;
}
.progress {
  --bs-progress-border-radius: 0rem;
}
.v-bar {
  width: 100%;
  height: 2em;
}
</style>