<template>
  <body id="page-top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg text-uppercase fixed-top" id="mainNav">
      <div class="container">
        <a class="navbar-brand" href="#page-top"
          >AI Tallying <i class="fa fa-circle Blink"></i
        ></a>

        <button
          class="
            navbar-toggler
            text-uppercase
            font-weight-bold
            bg-red
            text-white
            rounded
          "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item mx-0 mx-lg-1">
              <a class="nav-link py-3 px-0 px-lg-3 rounded" href="#">Results</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1">
              <a class="nav-link py-3 px-0 px-lg-3 rounded" href="/countwithus"
                >Count With Us</a
              >
            </li>
            <li class="nav-item mx-0 mx-lg-1">
              <a class="nav-link py-3 px-0 px-lg-3 rounded" href="#faq">FAQ</a>
            </li>
            <li class="nav-item mx-0 mx-lg-1">
              <a class="nav-link py-3 px-0 px-lg-3 rounded" href="#data"
                >DATA</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead bg-primary text-white text-center">
      <div class="container d-flex align-items-center flex-column">
        <!-- Masthead Heading-->
        <div @click="changeDataset()" class="btn btn-success mb-4">
          {{ buttonInfo }}
        </div>
        <table width="100%" class="ml-5">
          <tr width="100%">
            <td style="text-align: initial">
              <img class="candidate-image" src="/assets/odinga.jpg" alt="" />
              <h4 class="f-20 m-2">
                Raila
                <span class="count">{{
                  datasets[datakey].Raila.toLocaleString()
                }}</span>
              </h4>
            </td>
            <td style="text-align: right">
              <img class="candidate-image" src="/assets/ruto.jpg" alt="" />
              <h4 class="f-20 m-2">
                Ruto
                <span class="count">{{
                  datasets[datakey].Ruto.toLocaleString()
                }}</span>
              </h4>
            </td>
          </tr>
        </table>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light"></div>

        <div class="flex flex-col justify-center items-center mb-2">
          <h5 class="font-Inter font-semibold text-slate-500">
            50% + 1 to Win
          </h5>
          <i class="fas fa-arrow-down"></i>
        </div>

        <div class="bar w-100">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{
                width: percents.railaPercents + '%',
                backgroundColor: 'blue',
              }"
              sty
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
            <div
              class="progress-bar bg-warning"
              role="progressbar"
              :style="{ width: percents.rutoPercents + '%' }"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
        <!-- Masthead Subheading-->
        <p class="font-weight-light m-2 f-09">
          <small class=""
            >These are not official results but provisional results that might
            contain alot of errors. Please see IEBC website for more
            details</small
          >
        </p>
      </div>
    </header>

    <!-- FAQ section  -->

    <section class="page-section portfolio" id="faq">
      <div class="container">
        <!-- Portfolio Section Heading-->
        <h2
          class="
            page-section-heading
            text-center text-uppercase text-secondary
            mb-0
          "
        >
          FAQ
        </h2>
        <!-- Icon Divider-->
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-question"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- Portfolio Grid Items-->
        <div class="row justify-content-center">
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-crowd"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-crowd"
                >
                  What is crowd sourced/sourcing?
                </button>
              </h2>
              <div
                id="panelsStayOpen-crowd"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <p>
                    You can help us improve our data by verifying our data, we
                    will then update the tally every five minutes.The more
                    people who verify the data the better. Start here:
                  </p>

                  <a href="/countwithus" class="btn btn-success"
                    >Click here to Verify Data</a
                  >
                  <br />
                  Dont forget to
                  <a
                    href="https://api.whatsapp.com/send?&text=Help%20verify%20elections%20forms%20and%20see%20live%20results%0Ahttps%3A%2F%2Fkenyaelection.live%2F%20%20%0A"
                    target="_blank"
                    tooltip="Share on WhatsApp"
                  >
                    share the link</a
                  >
                  to get more people verifying the data.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  How was this data tallied?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  This data was tallied by using Machine Learning and by using
                  volunteers, by identifying handwritten texts from the IEBC
                  forms. These forms can be publically obtained form the
                  following link:
                  <a href="https://forms.iebc.or.ke"
                    >https://forms.iebc.or.ke</a
                  >
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  How accurate is this data?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div class="accordion-body">
                  <strong
                    >Unfortunately the method used is fairly inaccurate.</strong
                  >
                  It should not be relied on. The purpose of this project was to
                  assess the performance of current OCR technologies.
                  <p>
                    However, it is important to note that the models used for
                    this project were not trained for along time. Mostly due to
                    limited labelled data. Once the official tally is complete,
                    we can assess the performance of the models and improve on
                    it.,
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  How does the ML method work?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div class="accordion-body">
                  <span> Its a fairly long process </span>
                  <ol>
                    <li>
                      The data is first obtained from the official website in
                      PDF form
                    </li>
                    <li>
                      The PDFs are the converted to images for easier processing
                    </li>
                    <li>
                      The images are then cropped using a CNN deep neural
                      network. Specifically the section with the handwritten
                      tally.
                    </li>
                    <li>
                      The handwritten tally is then fed to an OCR model for text
                      extraction.
                    </li>
                    <li>
                      The extracted text is then parsed and the numbers are
                      extracted.
                    </li>
                    <li>
                      The numbers are then fed to Database, which is then
                      relayed here
                    </li>
                  </ol>
                  Please see the data set below for more information
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  Can I view the final data?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour"
              >
                <div class="accordion-body">
                  Yes you can. Please refer to this
                  <a
                    href="https://github.com/ib0b/kenya-elections-ocr"
                    target="_blank"
                    rel="noopener noreferrer"
                    >github repository</a
                  >
                  The data is made available as CSV. Note that this is not the
                  official data, but crowdsourced from the public.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <h2
      class="
        page-section-heading
        text-center text-uppercase text-secondary
        mb-0
      "
    >
      <a href="/countwithus" class="btn btn-success">
        Click here to Verify Data
      </a>
    </h2>
    <!-- Portfolio Section-->
    <section class="page-section portfolio" id="data">
      <div class="container">
        <!-- Portfolio Section Heading-->
        <h2
          class="
            page-section-heading
            text-center text-uppercase text-secondary
            mb-0
          "
        >
          AI DATA
        </h2>
        <!-- Icon Divider-->
        <div class="divider-custom">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- Portfolio Grid Items-->
        <div class="justify-content-center">
          <h6 class="text-center">
            Please note this is a partial snippet of the data, since all the
            data is too large to be displayed
          </h6>
          <div class="table-container" style="overflow-x: auto">
            <table class="table table-striped m-2">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Form ID</th>
                  <th scope="col">County</th>
                  <th scope="col">Raila</th>
                  <th scope="col">Ruto</th>
                  <th scope="col">George</th>
                  <th scope="col">David</th>
                  <th scope="col">Image</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(station, index) in stations" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ station.fileName }}</td>
                  <td>{{ station.folder }}</td>
                  <td>{{ station.Raila }}</td>
                  <td>{{ station.Ruto }}</td>
                  <td>{{ station.WJ }}</td>
                  <td>{{ station.David }}</td>
                  <td @click="clickedImage(station)">
                    <div class="btn btn-success">View Analysis</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="stations.length < 100 && !loading"
            class="btn btn-primary"
            @click="loadMore"
          >
            Load More
          </div>
          <div v-if="loading" class="btn btn-primary">Loading</div>
        </div>
      </div>
    </section>
    <!-- About Section-->
    <section class="page-section bg-primary text-white mb-0" id="about">
      <div class="container">
        <!-- About Section Heading-->
        <h2 class="page-section-heading text-center text-uppercase text-white">
          About
        </h2>
        <!-- Icon Divider-->
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- About Section Content-->
        <div class="row">
          <div class="col-lg-12 ms-auto">
            <p class="lead">
              Please note this is a datascience showcase and should not be
              treated as factual or official information. Please visit
              <a
                href="https://www.iebc.or.ke"
                class="text-bg-primary"
                target="_blank"
                >iebc.or.ke</a
              >
              for more information. This website is not affiliated with IEBC or
              any other electoral body
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Copyright Section-->
    <div class="copyright py-4 text-center text-white">
      <div class="container">
        <small>Copyright &copy; 2022</small>
      </div>
    </div>
    <!-- Portfolio Modals-->

    <!-- Bootstrap core JS-->
    <!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script> -->
    <!-- Core theme JS-->

    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
    <!-- * *                               SB Forms JS                               * *-->
    <!-- * * Activate your form at https://startbootstrap.com/solution/contact-forms * *-->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
  </body>
</template>

<script>
const axios = require("axios");
import config from "./config.js";
import state from "./state";
export default {
  name: "App",

  data: () => ({
    datasets: {
      dashboard: {
        Raila: 0,
        Ruto: 0,
        WJ: 0,
        David: 0,
      },
      ai: {
        Raila: 6683469,
        Ruto: 6691224,
        WJ: 59206,
        David: 31171,
      },
      official: {
        Raila: 6942930,
        Ruto: 7176141,
        WJ: 61989,
        David: 31987,
        filesWithSubmissions: 46201,
      },
    },
    buttonInfo: "You Are Viewing Crowd Sourced Results",
    datakey: "dashboard",
    stations: [],
    loading: false,
    percents: {
      railaPercents: 0,
      rutoPercents: 0,
    },
  }),
  methods: {
    changeDataset() {
      if (this.datakey == "official") {
        this.datakey = "dashboard";
        this.buttonInfo = "You Are Viewing Crowd Sourced Results";
      } else {
        this.buttonInfo = "You Are Viewing Offficial Results";
        this.datakey = "official";
      }
      this.setPercents();
    },
    async getDashboard() {
      let res = await axios.get(config.api.dashboard);
      this.datasets.dashboard = res.data;
      this.setPercents();
    },
    clickedImage(station) {
      // console.log("home:", station);
      state.ImageViewer.station = station;
      this.$router.push({
        name: "ImageViewer",
        params: {
          id: station.fileName,
        },
      });
    },
    async loadMore() {
      this.loading = true;
      let res = await axios.get("/assets/nairobi.json");
      let stations = res.data;
      stations.forEach((station) => {
        station.awsData = JSON.parse(station.awsData);
      });
      this.stations = stations;
      state.stations = stations;
      this.loading = false;
    },
    setPercents() {
      let Raila = this.datasets[this.datakey].Raila;
      let Ruto = this.datasets[this.datakey].Ruto;
      this.percents.railaPercents = (Raila / (Ruto + Raila)) * 100;
      this.percents.rutoPercents = (Ruto / (Ruto + Raila)) * 100;
    },
  },
  async mounted() {
    this.getDashboard();
    //get nairobi.json
    let res = await axios.get("/assets/mini.json");
    let stations = res.data;
    stations.forEach((station) => {
      station.awsData = JSON.parse(station.awsData);
    });
    this.setPercents();
    this.stations = stations;
    state.stations = stations;
    // console.log(stations);
  },
};
</script>

<style>
.candidate-image {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: white;
  border-width: 3px;
  border-style: solid;
}
header {
  background: url(assets/ai.jpg);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}
.count {
  font-size: 2.2rem;
  font-weight: 600;
  text-shadow: 2px 2px #000000;
}
.Blink {
  color: red;
  animation: blinker 0.7s cubic-bezier(1, 0.8, 0.2, 0.1) infinite alternate;
}
@keyframes blinker {
  from {
    opacity: 1;
    color: red;
  }
  to {
    opacity: 0.5;
    color: #850000;
    /* color: #979797; */
  }
}
</style>
