<template>
<div class="m-2">
  <a href="/#data" class="btn btn-primary mb-2">&lt; Back</a>
  <h2 class="text-center">OCR TALLYING</h2>
</div>

<!-- loading -->
<div class="d-flex justify-content-center align-items-center" style="height:50vh"  v-if="loadedImages<maxImages">
  <div>
    <!-- <p class="p-5" style="font-size:4rem;">Loading... </p> -->
      <div class="spinner-border align-self-center"  role="status" style="width: 5rem; height: 5rem;">
        <span class="sr-only">Loading...</span>
      </div>
  </div>
  
</div>

<div class="container hidden" ref="container" > 
  <div class="row">
    <div class="col-lg-4 col-sm-12 p-2">
      <!-- full image card -->
        <div class="card" >
          <img class="card-img-top skeleton" id="fullImg" :src="fullImgPath" alt="Card image cap">
          <!-- <img :src="fullImgPath" alt=""> -->
          <div class="card-body">
            <p class="card-text">This is the full Image. Form 34A</p>
          </div>
        </div>
    </div>
    <!-- main prediction -->
    <div class="col-lg-8 col-sm-12 p-2">
       <div class="card p-4">
        
          <img id="image" class="card-img-top skeleton" :src="imgPath" alt="Card image cap" />
          <canvas id="myCanvas"  />
          <div class="card-body">
            
            <h5 class="card-title font-weight-bold">Form Name: {{ fileName }}</h5>
            <p class="card-text">
              The following is the processed text which might not be accurate.<br>
              <small class="text-danger">Please note that this AI model is not accurate and the results below will be incorrect. Please see IEBC for official communication</small>
              <table class="table">
              
                <tbody>
                  <tr>              
                    <td>Text Recognised:</td>
                    <td>{{station.predictionText}}</td>            
                  </tr>
                  <tr>              
                    <td>Raila Odinga:</td>
                    <td>{{station.Raila}}</td>            
                  </tr> 
                  <tr>              
                    <td>William Ruto:</td>
                    <td>{{station.Ruto}}</td>            
                  </tr> 
                  <tr>              
                    <td>David Waihiga:</td>
                    <td>{{station.WJ}}</td>            
                  </tr> 
                  <tr>              
                    <td>George Wajackoyah:</td>
                    <td>{{station.David}}</td>            
                  </tr> 
                              
                </tbody>
              </table>
            </p>
            <a href="/#data" class="btn btn-primary">&lt; Back</a>
          </div>
        </div>
    </div>
    
  </div>



</div>

 

 
</template>

<script>
//get image id from this.props.first
import state from "./state";
export default {
  name: "ImageViewer",
  data() {
    return {
      fileName: "",
      station: state.ImageViewer.station,
      awsData: null,
      imgPath: "",
      fullImgPath: "",
      loadedImages: 0,
      maxImages: 1,
    };
  },
  methods: {
    drawOverlay() {
      var canvas = document.getElementById("myCanvas");
      var img = document.getElementById("image");
      var context = canvas.getContext("2d");

      // context.drawImage(img, 10, 10);
      canvas.style.position = "absolute";
      canvas.style.left = img.offsetLeft + "px";
      canvas.style.top = img.offsetTop + "px";
      canvas.width = img.offsetWidth;
      canvas.height = img.offsetHeight;

      let polygons = this.station.awsData.Blocks;
      polygons.forEach((poly) => {
        context.strokeStyle = "red";
        context.lineWidth = 2;
        const points = poly.Geometry.Polygon;
        context.beginPath();
        context.moveTo(canvas.width * points[0].X, canvas.height * points[0].Y);
        points
          .slice(1)
          .forEach((point) =>
            context.lineTo(canvas.width * point.X, canvas.height * point.Y)
          );
        context.closePath();
        context.stroke();
      });

      // context.beginPath();
      // context.rect(0, 0, 20, 20);
      // // context.fillStyle = "yellow";
      // context.fill();
      // context.lineWidth = 3;
      // context.strokeStyle = "red";
      // context.stroke();
    },
    incrementImages() {
      this.loadedImages++;
      if (this.loadedImages == this.maxImages) {
        this.$refs.container.classList.remove("hidden");
        // console.log("loaded drawing");
        setTimeout(this.drawOverlay, 5);
      }
    },
  },
  beforeCreate() {
    // console.log("dsd", state.ImageViewer.station);
    if (state.ImageViewer.station == null)
      return (document.location = "/#data");
  },
  created() {
    this.fileName = state.ImageViewer.station.fileName;
    this.awsData = this.station.awsData;
    this.imgPath = `https://nairobi-processed.s3.amazonaws.com/crops/count/${this.fileName}.jpg`;
    this.fullImgPath = `https://nairobi-processed.s3.amazonaws.com/${this.fileName}.jpg`;

    // console.log("image", this.imgPath);
  },
  mounted() {
    // console.log("station", this.station);
    // console.log("loaded", this.loadedImages);
    // console.log(`datas`, JSON.parse(JSON.stringify(this.station.awsData)));
    document.getElementById("image").onload = () => {
      this.incrementImages();
      window.addEventListener("resize", this.drawOverlay);
    };
    document.getElementById("fullImg").onload = () => {
      // this.incrementImages();
      this.drawOverlay();
      // console.log("loaded", this.loadedImages);
    };
  },
};
</script>

<style>
.hidden {
  display: none;
}
canvas {
  position: absolute;
  /* height: 0; */
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
